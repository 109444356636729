import {
  LazyDialogProductAgeConfirm,
  LazyDialogProductMap,
  LazyDialogProductNotify,
  LazyDialogProductPartnerConfirm,
  LazyDialogProductQpConfirm,
  LazyDialogProductQuote,
  LazyDialogProductSoConfirm,
  LazyDialogShare,
} from '#components';
import type { MinimumAdvertisedPricePolicy } from '~/types/ecommerce';

export const useDialogs = () => {
  const { dialog } = useQuasar();
  const ageStore = useAgeStore();

  const ageDialog = (age = 21) => {
    if (ageStore.check(age)) {
      return Promise.resolve();
    }

    return new Promise<void>((resolve, reject) =>
      dialog({
        component: LazyDialogProductAgeConfirm,
        componentProps: {
          age,
        },
      })
        .onOk(() => {
          ageStore.confirm(age);
          resolve();
        })
        .onCancel(reject),
    );
  };

  const qpDialog = () => {
    return new Promise<void>((resolve, reject) =>
      dialog({
        component: LazyDialogProductQpConfirm,
      })
        .onOk(resolve)
        .onCancel(reject),
    );
  };

  const partnerDialog = () => {
    return new Promise<void>((resolve, reject) =>
      dialog({
        component: LazyDialogProductPartnerConfirm,
      })
        .onOk(resolve)
        .onCancel(reject),
    );
  };

  const soDialog = (type: 'Preorder' | 'Backorder') => {
    return new Promise<void>((resolve, reject) =>
      dialog({
        component: LazyDialogProductSoConfirm,
        componentProps: {
          type,
        },
      })
        .onOk(resolve)
        .onCancel(reject),
    );
  };

  const notifyDialog = (props: QuoteDialogProps) => {
    return new Promise<void>((resolve, reject) =>
      dialog({
        component: LazyDialogProductNotify,
        componentProps: props,
      })
        .onOk(resolve)
        .onCancel(reject),
    );
  };

  const quoteDialog = (props: QuoteDialogProps) => {
    return new Promise<void>((resolve, reject) =>
      dialog({
        component: LazyDialogProductQuote,
        componentProps: props,
      })
        .onOk(resolve)
        .onCancel(reject),
    );
  };

  const shareDialog = (url?: string) => {
    return new Promise<void>((resolve, reject) =>
      dialog({
        component: LazyDialogShare,
        componentProps: { url },
      })
        .onOk(resolve)
        .onCancel(reject),
    );
  };

  const mapDialog = (policy: MinimumAdvertisedPricePolicy) => {
    return new Promise<void>((resolve, reject) =>
      dialog({
        component: LazyDialogProductMap,
        componentProps: {
          policy,
        },
      })
        .onOk(resolve)
        .onCancel(reject),
    );
  };

  return {
    ageDialog,
    qpDialog,
    partnerDialog,
    soDialog,
    notifyDialog,
    quoteDialog,
    shareDialog,
    mapDialog,
  };
};
